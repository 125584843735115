<template>
    <div style="position:relative;">
      
      <img src="@/static/hospital.png" class="img-fluid float-right mt-3" style="height:110px;width:110px;" alt="">
      <!-- <svg style="position:absolute;top:0px;left:-24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000b76" fill-opacity="1" d="M0,192L48,181.3C96,171,192,149,288,122.7C384,96,480,64,576,58.7C672,53,768,75,864,96C960,117,1056,139,1152,128C1248,117,1344,75,1392,53.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> -->
      <div class="row justify-content-center" >
        <div class="col-md-12 mt-2">
          <br>
            <p class="text-2xl font-bold  " @click="$router.push('/admin/transaksi/apotek')">Dashboard</p>
            <hr class="style14 mt-2 mb-5">
            <p class="font-semibold">Selamat Datang Di Dashboard : <span class="text-lg font-bold capitalize">{{$store.state.users.nama}}</span> </p>
        </div>
      </div>
      <br>
      <br>
      <!-- ========================================= -->
        <div class="row justify-content-center sm:p-3 scrollnya" style="">
          <div class="col-12 mt-2"></div>
          <div class="col-md-12">
              <div class="grid-item " v-if="$store.state.users">
                  <div v-for="(item, index) in tdMenu" :key="index+'menu'" 
                  v-show="item.level.includes($store.state.users.level)"
                  class="btn btn-sm btn-dark text-white rounded-lg capitalize text-2xl p-2 py-4">
                      <router-link :to="item.link" style="color:white;">
                          <span class="typcn " :class="`typcn-${item.icon}`"></span> 
                        <br>
                        <p class="text-xl" v-html="item.name"></p>
                      </router-link>
                    </div>
              </div>
          </div>
        </div>
      <!-- ========================================= -->
      <hr>
      <div class="row justify-content-center">
        <div class="offset-sm-2 col-md-4 shadow rounded-lg scrollbarnya" style="height:200px;overflow:scroll;">
              Items Terlaris : 
              <div class="row font-bold mt-1" v-for="(item, index) in terlaris" :key="index+'terlaris'">
                <div class="col-6">
                  {{item.nama_produk}}
                </div>
                <div class="col-6 text-right">
                  {{item.total}}
                </div>
              </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4 text-xs mt-1 text-center ml-1">
              <div class="shadow rounded-lg p-4 font-bold">
              TOTAL PRODUK<br><span class="font-bold text-2xl">{{total}}</span>
              <span class="typcn typcn-dropbox text-2xl ml-2"></span>
              </div>
            </div>
            <div class="col-md-4 text-xs mt-1 text-center ml-1">
              <div class="shadow rounded-lg p-4 font-bold">
              TOTAL BARANG MASUK <br><span class="font-bold text-2xl">{{total_masuk}}</span>
              <span class="typcn typcn-dropbox text-2xl ml-2"></span>
              </div>
            </div>
            <div class="col-md-4 text-xs mt-1 text-center ml-1">
              <div class="shadow rounded-lg p-4 font-bold">
              TOTAL BARANG KELUAR <br><span class="font-bold text-2xl">{{total_keluar}}</span>
              <span class="typcn typcn-dropbox text-2xl ml-2"></span>
              </div>
            </div>
            <div class="col-md-4 text-xs mt-1 text-center ml-1">
              <div class="shadow rounded-lg p-4 font-bold">
              KATEGORI BARANG <br><span class="font-bold text-2xl">{{kategori_barang}}</span>
              <span class="typcn typcn-dropbox text-2xl ml-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>

    </div>
</template>
<script>
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
let sdb = new autophp();
import _ from 'lodash'
export default{
    mounted(){
      this.getData();
      // this.getTerlaris();
    },
    methods: {
      getData(){
        let arr=[]
        let that=this
        sdb.collection("app_kasir_produk").doc().get("*","where type='1' OR type='3' order by id").then(res => {
            this.total=res.length;
             let ids=res.map(e=>{
               return {
                 id:e.id,
                 nama_produk:e.nama_produk
               }
             })
             console.log('ids',ids)
            // sdb.collection("app_kasir_keranjang",false).doc().select(`select * from app_kasir_keranjang where posisi='nota'`).then(res => {
            // sdb.collection("app_kasir_keranjang",false).doc().select(`SELECT keranjang.id_produk, COUNT(keranjang.id_produk) AS jumlah FROM  app_kasir_keranjang keranjang WHERE keranjang.posisi = 'nota' GROUP BY keranjang.id_produk ORDER BY jumlah DESC `).then(res => {
            //     let terlaris=res;
            //     console.log('terlaris',res)
            //     ids.forEach(e=>{
            //       arr.push({
            //         id:e.id,
            //         nama_produk:e.nama_produk,
            //         total:res.reduce((k,n)=>{
            //           if(e.id==n.id_produk){
            //             return k+parseInt(n.jumlah)
            //           }
            //           return k
            //         },0)
            //       })
            //     })
            //   arr= _.orderBy(arr, ['total'],['desc']);
            //     that.terlaris=arr
            // });
            // console.log('ids',ids)
            this.$forceUpdate();
        });
        sdb.collection("app_kasir_stok_masuk").doc().get("*","order by id").then(res => {
            this.total_masuk=res.length;
            this.$forceUpdate();
        });
        sdb.collection("app_kasir_stok_keluar").doc().get("*","order by id").then(res => {
            this.total_keluar=res.length;
            this.$forceUpdate();
        });
        sdb.collection("app_kasir_produk_kategori").doc().get("*","order by id").then(res => {
            this.kategori_barang=res.length;
            this.$forceUpdate();
        });
      },
       async getData2(){
      //doc adalah select *
      let that=this;
      this.masuk=await sdb.collection("app_kasir_stok_masuk").doc().get("*","order by id").then(res => {
          return res
      });
      this.keluar=await sdb.collection("app_kasir_stok_keluar").doc().get("*","order by id").then(res => {
          return res
      });
      // this.transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id`).then(res => {
      //     return res
      // });
      // this.transaksi=await sdb.collection("app_kasir_transaksi_nota").doc().get("*","tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id").then(res => {
      //     return res
      // });
      sdb.collection("app_kasir_produk",false).doc().select(`select * from app_kasir_produk where type='1' `).then(res => {
        let data =res;
        data=data.map(e=>{
          return {
            ...e,
            // stok_akhir:parseInt(e.stok)+(that.masuk.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.keluar.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.transaksi.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0))
          }
        })
        this.datanya=data;
        let ids=data.map(e=>e.id)
        // console.log('ids',ids)
        this.$forceUpdate();
      });
    },
      getTerlaris(){
       sdb.collection("app_kasir_keranjang",false).doc().select(`select * from app_kasir_keranjang where posisi='nota'`).then(res => {
          //  console.log('terlaris',res);
           this.terlaris=res;
       });
      }
    },
    computed:{
      tdMenu(){
        let array=[]
        let data =this.listMenu
        data.forEach(e=>{
          e.menu.forEach(k=>{
            array.push(k)
          })
        })
        return array
      }
    },
    data(){
        return{
          total:0,
          total_masuk:0,
          total_keluar:0,
          kategori_barang:0,
          terlaris:[],
          datanya:[],
          listMenu:[
        {
          group:'toko',
          icon:'home-outline',
          level:['SuperAdmin'],
          menu:[
                {
                    name:"Toko",
                    link:'/admin/toko/setting',
                    level:['SuperAdmin'],
                    icon:'home'
                },
                {
                    name:"Cabang Toko",
                    link:'/admin/toko/cabang',
                    level:['SuperAdmin'],
                    icon:'home'
                },
                {
                    name:"Modal",
                    link:'/admin/toko/modal',
                    level:['SuperAdmin'],
                    icon:'at'
                },
                {
                    name:"Penambahan dana",
                    link:'/admin/toko/penambahan',
                    level:['SuperAdmin'],
                    icon:'download'
                },
                {
                    name:"Pengeluaran dana",
                    link:'/admin/toko/pengeluaran',
                    level:['SuperAdmin'],
                    icon:'upload'
                },
                  {
                    name:"List Photo",
                    link:'/admin/toko/photo',
                    level:['SuperAdmin'],
                    icon:'upload'
                },
               
          ]
        },
         {
          group:'produk',
          icon:'dropbox',
          level:['SuperAdmin','Admin','apoteker','dokter'],
          menu:[
                {
                    name:"Tambah Obat",
                    link:'/admin/produk?kategori=Obat',
                    level:['apoteker'],
                    icon:'dropbox'
                },
                {
                    name:"Tambah Jasa/Treatment",
                    link:'/admin/produk?kategori=Jasa',
                    level:['dokter'],
                    icon:'dropbox'
                },
                {
                    name:"produk/Barang",
                    link:'/admin/produk',
                    level:['SuperAdmin','Admin'],
                    icon:'dropbox'
                },
                {
                  name:"Kategori<br>Produk",
                    link:'/admin/produk/kategori',
                    level:['SuperAdmin','Admin'],
                    icon:'th-large'
                },
                {
                  name:"satuan<br>Produk",
                    link:'/admin/produk/satuan',
                    level:['SuperAdmin','Admin'],
                    icon:'sort-numerically'
                },
                {
                    name:"Barcode",
                    link:'/admin/produk/barcode',
                    level:['SuperAdmin','Admin'],
                    icon:'document'
                },
                // {
                //     name:"Diskon<br>Produk",
                //     link:'/admin/produk/diskon',
                //     icon:'tag'
                // },
          ]
        },
        {
          group:'Stok',
          icon:'download',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"Barang<br>Masuk/Beli",
                link:'/admin/stok/masuk',
                level:['SuperAdmin','Admin'],
                icon:'download'
            },
            {
                name:"Barang<br>Keluar",
                link:'/admin/stok/keluar',
                level:['SuperAdmin','Admin'],
                icon:'upload'
            },
          ]
        },
        {
          group:'supplier',
          icon:'group',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"Supplier",
                link:'/admin/supplier',
                level:['SuperAdmin','Admin'],
                icon:'group'
            },
          ]
        },
        {
          group:'Pasien',
          icon:'group-outline',
                level:['SuperAdmin','Admin','Kasir','dokter','adminapotek','nurse','apoteker','facial'],
          menu:[
            {
                name:"Pasien",
                link:'/admin/pelanggan',
                level:['SuperAdmin','Admin','Kasir','dokter','adminapotek','nurse','apoteker','facial'],
                icon:'group'
            },
             {
                name:"Membership",
                link:'/admin/pelanggan/membership',
                level:['SuperAdmin','Admin'],
                icon:'business-card'
            },
          ]
        },
        
        {
          group:'transaksi',
          icon:'shopping-cart',
          level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse','facial'],
          menu:[
            {
                name:"Transaksi",
                link:'/admin/transaksi/whistlist',
                level:['SuperAdmin','Kasir'],
                icon:'shopping-cart'
            },
            {
                name:"Transaksi (image)",
                link:'/admin/transaksi/whistlist2',
                level:['SuperAdmin','Kasir'],
                icon:'image'
            },
            {
                name:"Edit",
                link:'/admin/transaksi/edit',
                level:['SuperAdmin','Kasir'],
                icon:'cog'
            },
             {
                name:"Online",
                link:'/admin/transaksi/online',
                level:['SuperAdmin','Admin','Kasir'],
                icon:'world'
            },
            {
                name:"Transaksi",
                link:'/admin/transaksi/apotek',
                level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse','facial'],
                icon:'shopping-cart'
            },
            {
                name:"Ticket Flow",
                link:'/admin/transaksi/apotek/ticketflow',
                level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse','facial'],
                icon:'arrow-shuffle'
            },
             {
                name:"Report Perperiode",
                link:'/admin/report/penjualan/periode2',
                level:['SuperAdmin','Admin','Kasir','adminapotek','dokter'],
                icon:'document'
            },
          ]
        },
        {
          group:'report',
          icon:'chart-bar',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"List Report",
                link:'/admin/report',
                level:['SuperAdmin','Admin','Kasir'],
                icon:'chart-bar'
            },
          ]
        },
        {
          group:'user',
          icon:'user',
          level:['SuperAdmin'],
          menu:[
            {
                name:"User<br>Pengguna",
                link:'/admin/user',
                level:['SuperAdmin'],
                icon:'group'
            },
          ]
        }
      ]
        }
    },
    layout:'app_shop_admin'
}
</script>
<style scoped>
.grid-item {
display: grid;
    /* repeat(jumlah column, 1fr) */
    grid-template-columns: repeat(5, 2fr);
    /* repeat(jumlah row) */
    grid-template-rows: repeat(1, 1fr); 
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}
.scrollbarnya::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
.scrollbarnya::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 5px;
}
 
/* Handle */
.scrollbarnya::-webkit-scrollbar-thumb {
  cursor: grab;
  background: black; 
  border-radius: 5px;
}

/* Handle on hover */
.scrollbarnya::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
</style>